import { DialogContent, DialogTitle } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Page } from '../Admin'
import DefaultPage from '../templates/DefaultPage'
import EditDialog from './EditDialog'

function AddPageDialog({ open }: React.PropsWithChildren<{ open: boolean }>) {
  const history = useHistory()
  const [page, setPage] = useState<Page>({ title: '', name: '', template: '', navigate: true, containers: [] })
  const handleChange = useCallback((newPage: Page) => {
    setPage(newPage)
  }, [])
  return <EditDialog open={open} onClose={() => history.push('/edit')}>
    <DialogTitle>Pagina toevoegen</DialogTitle>
    <DialogContent>
      <DefaultPage page={page} onChange={handleChange} disableSave={false} showDelete={false} />
    </DialogContent>
  </EditDialog>
}

export default AddPageDialog
