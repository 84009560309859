import { AppBar, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Drawer, Icon, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles, TextField } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { usePage } from './hooks'
import { pages, blocks } from './templates'
import firebaseApp from 'firebase/app'
import { firebase, siteName } from './'
import AddPageDialog from './components/AddPageDialog'
import { ConfirmDeleteDialogProvider } from './components/ConfirmDeleteDialog'
import DefaultPage from './templates/DefaultPage'
import { AddBlockDialogProvider } from './components/AddBlockDialog'
import { useDoc } from './hooks/firebase'
import { Block } from './templates/blocks'
import InstagramIcon from './icons/instagram'
import YoutubeIcon from './icons/youtube'
import FacebookIcon from './icons/facebook'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}))

export type Page = {
  name: string,
  title: string,
  navigate: boolean,
  template: string,
  containers: Array<{ content: Block[] }>
}

export type SiteData = {
  title: string,
  pages: Page[],
  social: {
    instagram?: string,
    facebook?: string,
    youtube?: string
  }
}

function Login() {
  const { setUser } = useContext(SiteDataContext)
  const history = useHistory()
  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault()
    const email = e.currentTarget.elements.email.value
    const password = e.currentTarget.elements.password.value
    const response = await firebase.auth().signInWithEmailAndPassword(email, password)
    setUser(response.user)
    setTimeout(() => {
      history.goBack()
    }, 1)
  }, [setUser, history])
  return <Box height="100%" display="flex" alignItems="center" justifyContent="center">
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Inloggen" />
        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField label="E-mail" variant="outlined" margin="normal" name="email" />
          <TextField label="Wachtwoord" variant="outlined" type="password" name="password" />
        </CardContent>
        <CardActions><span style={{ flex: 1 }} /><Button type="submit" variant="contained" color="primary">Login</Button></CardActions>
      </Card>
    </form>
  </Box>
}

function Settings({ data, doc }: React.PropsWithChildren<{ data: SiteData | null, doc: { update: (data: Partial<SiteData>) => Promise<void> } }>) {
  const [social, setSocial] = useState(data?.social)
  useEffect(() => {
    data && setSocial(data?.social)
  }, [data])
  return <Box padding={2} display="flex" flexDirection="column" alignItems="start">
    <TextField margin="normal" variant="filled" label="Instagram" value={social?.instagram || ''} onChange={e => setSocial(s => ({ ...s, instagram: e.target.value }))} InputProps={{ startAdornment: <InputAdornment position="start"><InstagramIcon color="secondary" /></InputAdornment> }} />
    <TextField margin="normal" variant="filled" label="Facebook" value={social?.facebook || ''} onChange={e => setSocial(s => ({ ...s, facebook: e.target.value }))} InputProps={{ startAdornment: <InputAdornment position="start"><FacebookIcon color="secondary" /></InputAdornment> }} />
    <TextField margin="normal" variant="filled" label="YouTube" value={social?.youtube || ''} onChange={e => setSocial(s => ({ ...s, youtube: e.target.value }))} InputProps={{ startAdornment: <InputAdornment position="start"><YoutubeIcon color="secondary" /></InputAdornment> }} />
    <Button onClick={() => doc.update({ social })} variant="contained" color="primary">Opslaan</Button>
  </Box>
}

function EditPage() {
  const { page } = useParams<{ page?: string }>()
  const editPage = usePage(page)
  const [state, setState] = useState(editPage)
  const hasChanges = useMemo(() => JSON.stringify(editPage) !== JSON.stringify(state), [editPage, state])
  useEffect(() => {
    setState(editPage)
  }, [editPage])
  const handleChange = useCallback((page: Page) => {
    setState(page)
  }, [])
  if (state) {
    const EditComponent = pages[state.template as keyof typeof pages].Edit as React.FC<{ page: Page, onChange: (page: Page) => void }>
    //const PreviewComponent = pages[state.template as keyof typeof pages].Main as React.FC<{ page: Page }>
    return <Box display="flex" height="100%">
      <Box flex={1} overflow="auto">
        <DefaultPage onChange={handleChange} page={state} disableSave={!hasChanges}>
          <EditComponent page={state} onChange={handleChange} />
        </DefaultPage>
      </Box>
    </Box>
  } else {
    return null
  }
}

export const SiteDataContext = React.createContext<{ data: SiteData | null, save: (data: SiteData) => Promise<void>, user: firebaseApp.User | null, setUser: (user: firebaseApp.User | null) => void }>({ data: null, user: null, save: async () => { }, setUser: () => { } })

function Admin() {
  const [user, setUser] = useState<firebaseApp.User | null>(null)
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const openAddPageDialog = useMemo(() => pathname.indexOf('/page/add') >= 0, [pathname])
  const initialPath = useRef<string | null>(pathname)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user)
        setTimeout(() => {
          history.push(initialPath.current && initialPath.current !== '/edit/login' ? initialPath.current : '/edit')
        }, 100)
      } else {
        history.push('/edit/login')
      }
    })
  }, [history, initialPath])

  const logout = useCallback(async () => {
    await firebase.auth().signOut()
    setUser(null)
    history.push('/edit/login')
  }, [history])

  const [data, { loading, ...doc }] = useDoc<SiteData>('site-data', siteName)

  return <SiteDataContext.Provider value={{ data, user, setUser, save: doc.set }}>
    <ConfirmDeleteDialogProvider>
      <AddBlockDialogProvider>
        <Switch>
          <Route path="/edit/login" component={Login} />
          <Route>
            {!data ? <Box>loading</Box> : <Box display="flex" height="100%">
              {user && <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" anchor="left">
                <List disablePadding={true}>
                  <ListItem button={true}>
                    <ListItemIcon><Icon>chevron_left</Icon></ListItemIcon>
                    <ListItemText primary="Terug naar website" />
                  </ListItem>
                </List>
                <AppBar position="relative">
                  <List disablePadding={true}>
                    <ListItem>
                      <ListItemText primary={user.displayName || user.email} secondary={siteName} secondaryTypographyProps={{ color: 'inherit' }} />
                      <ListItemSecondaryAction>
                        <IconButton color="inherit" onClick={logout}><Icon>power_settings_new</Icon></IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </AppBar>
                <List dense={true} subheader={<ListSubheader>Pagina's</ListSubheader>} disablePadding={true}>
                  {data.pages.map(({ name, title, template }, k) => <React.Fragment key={k}>
                    <ListItem className={classes.nested} button={true} onClick={() => history.push(`/edit/page/${name}`)} selected={pathname.indexOf(`/edit/page/${name}`) >= 0}>
                      <ListItemText primary={title} />
                    </ListItem>
                    {blocks[template as keyof typeof blocks] && blocks[template as keyof typeof blocks].content && typeof blocks[template as keyof typeof blocks].content !== 'string' && (blocks[template as keyof typeof blocks].content as any[]).map(({ title, icon }, i: number) => {
                      return <ListItem key={i} button={true} className={classes.nested}>
                        <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
                        <ListItemText primary={title} />
                        <ListItemSecondaryAction><IconButton><Icon color="primary">add_circle</Icon></IconButton></ListItemSecondaryAction>
                      </ListItem>
                    })}
                  </React.Fragment>)}
                  <ListItem className={classes.nested} button={true} onClick={() => history.push('/edit/page/add')}>
                    <ListItemText primary="Pagina toevoegen" primaryTypographyProps={{ variant: 'button', color: 'primary', style: { textTransform: 'none' } }} />
                  </ListItem>
                </List>
                <Divider />
                <List disablePadding={true}>
                  <ListItem button={true} onClick={() => history.push(`/edit/settings`)} selected={pathname === '/edit/settings'}>
                    <ListItemIcon><Icon>settings</Icon></ListItemIcon>
                    <ListItemText primary="Instellingen" />
                  </ListItem>
                </List>
              </Drawer>}
              <Box flex={1} overflow="auto">
                <Switch>
                  <Route path={['/edit/page/:page/add/:id', '/edit/page/:page/add', '/edit/page/add', '/edit/page/:page/edit/:id', '/edit/page/:page']} component={EditPage} />
                  <Route path="/edit/settings"><Settings data={data} doc={doc} /></Route>
                  <Route><Redirect to={`/edit/page/${data.pages[0].name}`} /></Route>
                </Switch>
              </Box>
              <AddPageDialog open={openAddPageDialog} />
            </Box>}
          </Route>
        </Switch>
      </AddBlockDialogProvider>
    </ConfirmDeleteDialogProvider>
  </SiteDataContext.Provider>
}

export default Admin
