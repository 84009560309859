import { AppBar, Box, Button, Drawer, FormControlLabel, InputAdornment, makeStyles, MenuItem, Switch, TextField, Toolbar, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Page, SiteDataContext } from '../Admin'
import { ConfirmDeleteContext } from '../components/ConfirmDeleteDialog'
import { pages as templates } from './'
import { siteName } from '../'

const useStyles = makeStyles(theme => ({
  deleteButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.error.dark
    }
  },
  drawerPaper: {
    position: 'relative',
    width: 400
  }
}))

function DefaultPageForm<T extends Page>({ page, onChange, disableSave, showDelete }: React.PropsWithChildren<{ page: T, onChange: (page: T) => void, disableSave: boolean, showDelete?: boolean }>) {
  const params = useParams<{ page?: string }>()
  const history = useHistory()
  const { deleteButton } = useStyles()
  const pageTemplates: any[] = useMemo(() => Object.keys(templates).map(k => ({ ...templates[k as keyof typeof templates] })).filter((p: any) => p.type === 'page'), [])
  const { data, save } = useContext(SiteDataContext)
  const confirmDelete = useContext(ConfirmDeleteContext)
  const handleDelete = useCallback(() => {
    confirmDelete.open({
      deleteText: 'Weet je zeker dat je deze pagina wilt verwijderen?',
      onConfirm: async () => {
        if (data) {
          const index = data.pages.findIndex(p => p.name === params.page)
          index >= 0 && data.pages.splice(index, 1)
          await save(data)
          history.push('/edit')
        }
      }
    })
  }, [confirmDelete, data, params, save, history])
  return <Box display="flex" flexDirection="column" width="100%" padding={2}>
    <TextField margin="normal" variant="filled" label="Titel" value={page.title} onChange={e => onChange({ ...page, title: e.target.value })} required={true} />
    <Box display="flex" alignItems="center">
      <TextField margin="normal" variant="filled" label="Pad" value={page.name} required={true} InputProps={{ startAdornment: <InputAdornment position="start">{siteName}/</InputAdornment> }} onChange={e => onChange({ ...page, name: e.target.value })} />
      <FormControlLabel checked={page.navigate} control={<Switch color="primary" />} label={<span style={{ whiteSpace: 'nowrap' }}>Tonen in navigatie</span>} style={{ marginLeft: 16 }} onChange={e => onChange({ ...page, navigate: (e.target as any).checked })} />
    </Box>
    <TextField margin="normal" variant="filled" label="Template" value={page.template} required={true} select={true} fullWidth={true} style={{ flex: 1 }} onChange={e => onChange({ ...page, template: e.target.value })}>
      {pageTemplates.map(({ name, title }, k) => <MenuItem key={k} value={name}>{title}</MenuItem>)}
    </TextField>
    <Box marginTop={2}>
      <Button type="submit" variant="contained" color="primary" disabled={disableSave}>Opslaan</Button>
      {showDelete && <Button variant="contained" onClick={handleDelete} className={deleteButton}>Pagina verwijderen</Button>}
    </Box>
  </Box>
}

function DefaultPage<T extends Page>({ children, page, onChange, disableSave, showDelete }: React.PropsWithChildren<{ page: T, onChange: (page: T) => void, disableSave: boolean, showDelete?: boolean }>) {
  const params = useParams<{ page?: string }>() // TODO: may not be safe to use params.page for this...
  const history = useHistory()
  const { data, save } = useContext(SiteDataContext)
  const { drawerPaper } = useStyles()
  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault()
    if (data) {
      const index = data.pages.findIndex(p => p.name === params.page)
      if (index >= 0) {
        data.pages[index] = page
      } else {
        data.pages.push(page)
      }
      await save(data)
      params.page !== page.name && history.push(`/edit/page/${page.name}`)
    }
  }, [page, params, data, save, history])
  return <form onSubmit={handleSubmit} style={{ height: '100%' }}>
    {showDelete ? <Box display="flex" height="100%">
      <Box flex={1} padding={2}>
        {children}
      </Box>
      <Drawer classes={{ paper: drawerPaper }} variant="permanent" anchor="right">
        <AppBar position="relative" color="default">
          <Toolbar>
            <Typography variant="h5">Pagina bewerken</Typography>
            <span style={{ flex: 1 }} />
            <Button type="submit" variant="contained" color="primary" disabled={disableSave}>Opslaan</Button>
          </Toolbar>
        </AppBar>
        <DefaultPageForm page={page} onChange={onChange} showDelete={showDelete} disableSave={disableSave} />
      </Drawer>
    </Box> :
      <DefaultPageForm page={page} onChange={onChange} showDelete={showDelete} disableSave={disableSave} />
    }
  </form>
}

DefaultPage.defaultProps = {
  showDelete: true
}

export default DefaultPage
