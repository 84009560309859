import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import React, { useCallback, useState } from 'react'

export const ConfirmDeleteContext = React.createContext<{ open: (options: { onConfirm: () => Promise<void>, onCancel?: () => void, deleteText?: string }) => void }>({ open: () => { } })

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  cancelButton: {
    color: theme.palette.error.main
  }
}))

export function ConfirmDeleteDialogProvider({ children }: React.PropsWithChildren<any>) {
  const [state, setState] = useState<{ open: boolean, deleteText?: string, onConfirm: () => Promise<void>, onCancel?: () => void }>({ open: false, onConfirm: async () => { } })
  const { cancelButton } = useStyles()
  const handleOpen = useCallback(({ onConfirm, onCancel, deleteText }: { onConfirm: () => Promise<void>, onCancel?: () => void, deleteText?: string }) => {
    setState({ open: true, onConfirm, onCancel, deleteText })
  }, [])
  const handleClose = useCallback(() => {
    state.onCancel && state.onCancel()
    setState({ open: false, onConfirm: async () => { }, onCancel: undefined, deleteText: undefined })
  }, [state])
  const handleConfirm = useCallback(() => {
    state.onConfirm()
    setState({ open: false, onConfirm: async () => { }, onCancel: undefined, deleteText: undefined })
  }, [state])
  return <ConfirmDeleteContext.Provider value={{ open: handleOpen }}>
    {children}
    <Dialog open={state.open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Verwijderen</DialogTitle>
      <DialogContent>
        <DialogContentText>{state.deleteText ? state.deleteText : 'Weet je zeker dat je dit item wilt verwijderen?'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={cancelButton}>Annuleren</Button>
        <Button onClick={handleConfirm} color="primary">Verwijderen</Button>
      </DialogActions>
    </Dialog>
  </ConfirmDeleteContext.Provider>
}
