import { Box, Card, CardContent, Divider, Fab, Icon, IconButton, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React, { useCallback, useContext } from 'react'
import { PageTemplate } from '.'
import { Page } from '../Admin'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { AddBlockContext } from '../components/AddBlockDialog'
import { BlockDefinition, Block } from './blocks'
import { ConfirmDeleteContext } from '../components/ConfirmDeleteDialog'
import { blocks } from '.'

function Main({ page }: { page: Page }) {
  return <Box display="flex" flexWrap="wrap" padding={1} height="100%" justifyContent="center" alignContent="flex-start">
    {(page.containers ? page.containers[0].content : []).map(({ template, cols, props }, k) => {
      const { Main } = blocks[template as keyof typeof blocks]
      return <Box width={`${100 / 12 * cols}%`} key={k}><Main props={props} /></Box>
    })}
  </Box>
}

const useEditStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.grey[300],
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    height: '100%',
    overflowX: 'hidden'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1)
  }
}))

const DragHandle = SortableHandle(() => <Box marginRight={2} overflow="hidden" width={16} height={18} style={{ cursor: 'move' }}><Icon color="disabled">dialpad</Icon></Box>)

const SortableGridTile = SortableElement(({ template, cols, props, onRemove, onChange }: Omit<Block, 'hash'> & { onRemove: () => void, onChange: (property: keyof Block, value: any) => void }) => {
  const { title, Edit } = blocks[template as keyof typeof blocks]
  return <Box width={`${100 / 12 * cols}%`} padding={1}>
    <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent style={{ paddingTop: 8, paddingBottom: 8 }}>
        <Box display="flex" alignItems="center">
          <DragHandle />
          <Typography>{title}</Typography>
          <TextField label="Breedte" select={true} variant="filled" margin="none" style={{ marginLeft: 16 }} value={cols} onChange={e => onChange('cols', Number(e.target.value))}>
            {Array(12).fill(null).map((v, i) => <MenuItem key={i} value={i + 1}>{`${i + 1} kolom${(i + 1) !== 1 ? 'men' : ''}`}</MenuItem>)}
          </TextField>
          <span style={{ flex: 1 }} />
          <IconButton onClick={onRemove}><Icon>delete</Icon></IconButton>
        </Box>
      </CardContent>
      <Divider />
      <Edit props={props} onChange={newProps => onChange('props', newProps)} />
    </Card>
  </Box>
})

const SortableGrid = SortableContainer(({ items, onRemove, onChange }: { items: Block[], onRemove: (index: number) => void, onChange: (items: Block[]) => void }) => {
  const handleChange = useCallback((hash: string, property: keyof Block, value: number | string) => {
    const index = items.findIndex(i => i.hash === hash)
    items[index] = { ...items[index], [property]: value as never }
    onChange([...items])
  }, [items, onChange])
  return <Box display="flex" flexWrap="wrap" padding={1} height="100%" justifyContent="center">
    {items.map(({ template, cols, props, hash }, k) => <SortableGridTile key={hash} index={k} template={template} cols={cols} props={props} onChange={(property, value) => handleChange(hash, property, value)} onRemove={() => onRemove(k)} />)}
  </Box>
})

function Edit({ page, onChange }: { page: Page, onChange: (page: Page) => void }) {
  const { container, fab } = useEditStyles()
  const addBlockDialog = useContext(AddBlockContext)
  const confirmDelete = useContext(ConfirmDeleteContext)

  const handleAddBlock = useCallback(() => {
    addBlockDialog.open({
      onSave: async (block: BlockDefinition) => {
        const current = page.containers && page.containers[0] && page.containers[0].content ? page.containers[0].content : []
        onChange({ ...page, containers: [{ content: [...current, { template: block.name, cols: 12, hash: uuid() }] }] })
      }
    })
  }, [addBlockDialog, page, onChange])

  const handleRemoveBlock = useCallback((index: number) => {
    confirmDelete.open({
      deleteText: 'Weet je zeker dat je dit blok wilt verwijderen?',
      onConfirm: async () => {
        page.containers[0].content.splice(index, 1)
        onChange({ ...page, containers: [{ content: [...page.containers[0].content] }] })
      }
    })
  }, [confirmDelete, page, onChange])

  const handleSortEnd = useCallback(({ oldIndex, newIndex }) => {
    onChange({ ...page, containers: [{ content: arrayMove(page.containers[0].content, oldIndex, newIndex) }] })
  }, [page, onChange])

  return <Box position="relative" className={container}>
    <SortableGrid axis="xy" items={page.containers && page.containers[0] && page.containers[0].content ? page.containers[0].content : []} onSortEnd={handleSortEnd} onRemove={handleRemoveBlock} useDragHandle={true} onChange={blocks => onChange({ ...page, containers: [{ content: blocks }] })} />
    <div className={fab}><Fab variant="round" color="primary" onClick={handleAddBlock}><Icon>add</Icon></Fab></div>
  </Box>
}

const definition: PageTemplate = {
  type: 'page',
  name: 'simple_page',
  title: 'Simpele pagina',
  Main,
  Edit
}

export default definition
