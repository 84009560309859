import { AppBar, Box, Button, CircularProgress, createMuiTheme, Drawer, Hidden, Icon, IconButton, makeStyles, MenuItem, ThemeProvider, Toolbar, Typography } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useDoc } from './hooks/firebase'
import { Page, SiteData } from './Admin'
import { siteName } from '.'
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { pages as pageTemplates } from './templates'
import InstagramIcon from './icons/instagram'
import FacebookIcon from './icons/facebook'
import YoutubeIcon from './icons/youtube'

const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: blueGrey
  }
})

const useStyles = makeStyles({
  appBar: {
    boxShadow: 'none',
    background: 'none'
  },
  menuButton: {
    marginRight: 8,
    padding: '5px 7px'
  },
  activeMenuButton: {
    marginRight: 8,
    padding: '6px 8px'
  }
})

function MenuButton({ path, children }: PropsWithChildren<{ path: string }>) {
  const { activeMenuButton, menuButton } = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const isActive = useMemo(() => pathname.indexOf(path) === 0, [pathname, path])
  return <Button onClick={() => history.push(path)} className={!isActive ? activeMenuButton : menuButton} variant={isActive ? 'outlined' : 'text'} color={isActive ? 'secondary' : 'default'}>{children}</Button>
}

function DynamicPage({ pages }: PropsWithChildren<{ pages: Page[] }>) {
  const { page } = useParams<{ page?: string }>()
  const match = useMemo(() => pages.find(p => p.name === page), [page, pages])
  if (match) {
    const Page = pageTemplates[match?.template as keyof typeof pageTemplates].Main as React.FC<{ page: Page }>
    return <Page page={match} />
  } else {
    return <Redirect to={`/${pages[0].name}`} />
  }

}

function openInNewTab(url: string) {
  const tab = window.open(url, '_blank')
  tab?.focus()
}


function App() {
  const { appBar } = useStyles()
  const [data] = useDoc<SiteData>('site-data', siteName)
  const [showSidebar, setShowSidebar] = useState(false)
  const history = useHistory()
  const { pathname } = useLocation()

  const onMenuItemClick = useCallback((path: string) => {
    history.push(path)
    setShowSidebar(false)
  }, [history])

  return <ThemeProvider theme={theme}>
    <Box display="flex" flexDirection="column" alignItems="center" height="100%">
      <AppBar color="default" className={appBar} position="relative">
        <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
          <Hidden mdUp>
            <IconButton onClick={() => setShowSidebar(true)}><Icon color="secondary">menu</Icon></IconButton>
            <span style={{ flex: 1 }} />
          </Hidden>
          <Hidden smDown>
            <>
            {data?.pages.map((page, k) => <MenuButton path={`/${page.name}`} key={k}>{page.title}</MenuButton>)}
            </>
          </Hidden>
          {data?.social && <Box>
            {data?.social.instagram && <IconButton onClick={() => openInNewTab(data.social.instagram || '')}><InstagramIcon color="secondary" /></IconButton>}
            {data?.social.facebook && <IconButton onClick={() => openInNewTab(data.social.facebook || '')}><FacebookIcon color="secondary" /></IconButton>}
            {data?.social.youtube && <IconButton onClick={() => openInNewTab(data.social.youtube || '')}><YoutubeIcon color="secondary" /></IconButton>}
          </Box>}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={showSidebar} onClose={() => setShowSidebar(false)}>
        {data?.pages.map((page, k) => <MenuItem onClick={() => onMenuItemClick(`/${page.name}`)} selected={pathname.indexOf(`/${page.name}`) === 0} key={k}>{page.title}</MenuItem>)}
      </Drawer>
      <Box paddingTop={2} flex={1} maxWidth={1440} width="100%">
        <Switch>
          <Route path={['/:page', '/']}>{data ? <DynamicPage pages={data?.pages} /> : <CircularProgress variant="indeterminate" />}</Route>
        </Switch>
      </Box>
      <Box component="footer" paddingBottom={2} marginTop={2}><Typography variant="body2" color="textSecondary">&copy; 2010 - {new Date().getFullYear()}, Jitske Bakker | BRN: 54612314</Typography></Box>
    </Box>
  </ThemeProvider>
}

export default App
