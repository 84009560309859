import React, { useCallback, useState } from 'react'
import { Editor, EditorUtils } from '@progress/kendo-react-editor'
import { EditorView } from 'prosemirror-view'
import { Icon, IconButton, makeStyles, Popover, TextField } from '@material-ui/core'
import { toggleMark } from 'prosemirror-commands'
import { undo, redo } from 'prosemirror-history'
import { EditorState, TextSelection } from 'prosemirror-state'
import { MarkType, ResolvedPos } from 'prosemirror-model'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .k-editor-content': {
      height: '100%'
    },
    '& .k-button-group': {
      display: 'inline'
    }
  }
})

function RichTextEditor({ value, onChange }: { value: string, onChange: (e: { target: any }, value: string) => void }) {
  const { root } = useStyles()
  return <Editor
    tools={[
      [createUndoRedoButton(undo, 'undo'), createUndoRedoButton(redo, 'redo')],
      [BoldButton, ItalicButton, UnderlineButton],
      [LinkButton, ClearFormatButton]
    ]}
    contentStyle={{ height: '100%', width: '100%' }}
    defaultContent={value}
    onChange={e => onChange(e, e.html)}
    className={root}
  />
}

export default RichTextEditor

function LinkButton({ view }: React.PropsWithChildren<{ view?: any }>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const inputRef = React.useRef<HTMLInputElement>()
  const nodeType = view && view.state.schema.nodes['text']
  const canInsert = view && EditorUtils.canInsert(view.state, nodeType)
  const isActive = view ? selectionHasMark(view.state, view.state.schema.marks.link) : false
  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
    setTimeout(() => {
      if (isActive) {
        const mark = selectionGetMark(view.state, view.state.schema.marks.link)
        if (mark && inputRef.current) {
          inputRef.current.value = mark.attrs.href
        }
      }
      inputRef.current?.focus()
    })
  }, [inputRef, isActive, view])
  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    const link = e.target.elements.link.value
    if (isActive) {
      updateMark(view.state.schema.marks.link, { href: link.indexOf('http') !== 0 ? 'http://' + link : link, target: '_blank' })(view.state, view.dispatch)
    } else {
      toggleMark(view.state.schema.marks.link, { href: link.indexOf('http') !== 0 ? 'http://' + link : link, target: '_blank' })(view.state, view.dispatch)
    }
    setAnchorEl(null)
  }, [view, isActive])
  return <React.Fragment>
    <IconButton size="small" onClick={handleClick} disabled={!canInsert} color={isActive ? 'primary' : 'default'}><Icon>link</Icon></IconButton>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          name="link"
          label="Link"
          variant="filled"
          margin="none"
          inputRef={inputRef}
        />
      </form>
    </Popover>
  </React.Fragment>
}

function ClearFormatButton({ view }: React.PropsWithChildren<{ view?: EditorView }>) {
  const handleClick = useCallback(() => {
    if (view) {
      const { from, to } = view.state.selection
      const selection = view.state.doc.cut(from, to)
      const node = view.state.schema.text(selection.textContent)
      view.dispatch(view.state.tr.replaceRangeWith(from, to, node))
    }
  }, [view])
  return <IconButton size="small" onClick={handleClick} ><Icon>format_clear</Icon></IconButton>
}

function BoldButton({ view }: React.PropsWithChildren<{ view?: EditorView }>) {
  const handleClick = useCallback(() => {
    view && toggleMark(view.state.schema.marks.strong)(view.state, view.dispatch)
  }, [view])
  const isActive = view ? selectionHasMark(view.state, view.state.schema.marks.strong) : false
  return <IconButton size="small" onClick={handleClick} color={isActive ? 'primary' : 'default'}><Icon>format_bold</Icon></IconButton>
}

function ItalicButton({ view }: React.PropsWithChildren<{ view?: EditorView }>) {
  const handleClick = useCallback(() => {
    view && toggleMark(view.state.schema.marks.em)(view.state, view.dispatch)
  }, [view])
  const isActive = view ? selectionHasMark(view.state, view.state.schema.marks.em) : false
  return <IconButton size="small" onClick={handleClick} color={isActive ? 'primary' : 'default'}><Icon>format_italic</Icon></IconButton>
}

function UnderlineButton({ view }: React.PropsWithChildren<{ view?: EditorView }>) {
  const handleClick = useCallback(() => {
    view && toggleMark(view.state.schema.marks.u)(view.state, view.dispatch)
  }, [view])
  const isActive = view ? selectionHasMark(view.state, view.state.schema.marks.u) : false
  return <IconButton size="small" onClick={handleClick} color={isActive ? 'primary' : 'default'}><Icon>format_underline</Icon></IconButton>
}

function createUndoRedoButton(command: typeof undo | typeof redo, icon: 'undo' | 'redo') {
  return function ({ view }: React.PropsWithChildren<{ view?: EditorView }>) {
    const handleClick = useCallback(() => {
      view && command(view.state, view.dispatch)
    }, [view])
    return <IconButton size="small" onClick={handleClick}><Icon>{icon}</Icon></IconButton>
  }
}

function selectionHasMark(state: EditorState, markType: MarkType) {
  const position = { from: state.selection.from, to: state.selection.to }
  if (position.from === position.to) {
    const nodeBefore = state.selection instanceof TextSelection ? state.selection.$cursor!.nodeBefore : undefined
    if (nodeBefore) {
      return nodeBefore.marks.findIndex((m: { type: MarkType }) => m.type === markType) >= 0
    }
    return false
  } else {
    return state.doc.rangeHasMark(position.from, position.to, markType)
  }
}

function selectionGetMark(state: any, markType: MarkType) {
  const position = state.tr.doc.resolve(state.selection.from)
  const start = position.parent.childAfter(position.parentOffset)
  if (start.node) {
    const mark = start.node.marks.find((mark: any) => mark.type === markType)
    if (mark) {
      return mark
    }
  }
}

function updateMark(markType: MarkType, attrs: object) {
  return function (state: EditorState, dispatch: any) {
    if (dispatch) {
      let position = state.tr.doc.resolve(state.selection.from)
      const { from, to } = positionOfMark(position, markType) || { from: state.selection.from, to: state.selection.to }
      dispatch(state.tr.addMark(from, to, markType.create(attrs)), state.tr.scrollIntoView())
    }
    return true
  }
}

function positionOfMark(pos: ResolvedPos, markType?: MarkType) {
  const { parent, parentOffset } = pos
  const start = parent.childAfter(parentOffset)
  if (!start.node) return null
  const match = markType ? start.node.marks.find((mark) => mark.type === markType) : start.node.marks[0]
  if (!match) return null
  let startIndex = pos.index()
  let startPos = pos.start() + start.offset
  let endIndex = startIndex + 1
  let endPos = startPos + start.node.nodeSize
  while (startIndex > 0 && match.isInSet(parent.child(startIndex - 1).marks)) {
    startIndex -= 1
    startPos -= parent.child(startIndex).nodeSize
  }
  while (endIndex < parent.childCount && match.isInSet(parent.child(endIndex).marks)) {
    endPos += parent.child(endIndex).nodeSize
    endIndex += 1
  }
  return { from: startPos, to: endPos }
}

/*
  const attrs = { title: 'mylink', href: 'google.com' }
  const schema = view.state.schema
  const selection = view.state.doc.cut(view.state.selection.from, view.state.selection.to)
  const node = schema.text(selection.textContent, [schema.marks.link.create(attrs)])
  view.dispatch(view.state.tr.replaceSelectionWith(node, false))
*/
