import { Box, Button, ButtonBase, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, makeStyles, Slide, Typography } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import React, { useCallback, useState } from 'react'
import { blocks } from '../templates'
import { BlockDefinition } from '../templates/blocks'

export const AddBlockContext = React.createContext<{ open: (options: { onSave: (block: BlockDefinition) => Promise<void>, onCancel?: () => void, deleteText?: string }) => void }>({ open: () => { } })

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  cancelButton: {
    color: theme.palette.error.main
  }
}))

export function AddBlockDialogProvider({ children }: React.PropsWithChildren<any>) {
  const [state, setState] = useState<{ open: boolean, deleteText?: string, onSave: (block: BlockDefinition) => Promise<void>, onCancel?: () => void }>({ open: false, onSave: async (block: BlockDefinition) => { } })
  const { cancelButton } = useStyles()
  const handleOpen = useCallback(({ onSave, onCancel, deleteText }: { onSave: (block: BlockDefinition) => Promise<void>, onCancel?: () => void, deleteText?: string }) => {
    setState({ open: true, onSave, onCancel, deleteText })
  }, [])
  const handleClose = useCallback(() => {
    state.onCancel && state.onCancel()
    setState({ open: false, onSave: async () => { }, onCancel: undefined, deleteText: undefined })
  }, [state])
  const handleSave = useCallback((block: BlockDefinition) => {
    state.onSave(block)
    setState({ open: false, onSave: async () => { }, onCancel: undefined, deleteText: undefined })
  }, [state])
  return <AddBlockContext.Provider value={{ open: handleOpen }}>
    {children}
    <Dialog open={state.open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Kies een template</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box display="flex" flexWrap="wrap">
            {Object.keys(blocks).map(name => {
              const { title, content, icon, ...block } = blocks[name as keyof typeof blocks]
              return <Box key={name}>
                <Card style={{ margin: 8 }}>
                  <ButtonBase onClick={() => handleSave({ ...block, icon, title, content, name })}>
                    <Box display="flex" flexDirection="column">
                      <CardContent style={{ paddingTop: 8, paddingBottom: 8, textAlign: 'left', display: 'flex' }}>
                        <Icon>{icon}</Icon>
                        <Typography style={{ marginLeft: 8 }}>{title}</Typography>
                      </CardContent>
                    </Box>
                  </ButtonBase>
                </Card>
              </Box>
            })}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={cancelButton}>Annuleren</Button>
      </DialogActions>
    </Dialog>
  </AddBlockContext.Provider>
}
