import { Box, Button, TextField, TextFieldProps, Typography } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { BlockDefinition, BlockProps } from '.'
import axios from 'axios'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

type ContactFormBlockProps = BlockProps<{
  apiKey?: string
  subHeading?: string
  feedbackMessage?: string
}>

function EnhancedTextField({ errorMessage, validate, required, value, ...props }: TextFieldProps & { errorMessage?: string, validate?: (value: unknown) => boolean }) {
  const [touched, setTouched] = useState(false)
  const handleBlur = useCallback(() => {
    setTouched(true)
  }, [])
  const hasError = useMemo(() => touched ? validate ? validate(value) : required && (!value || value === '') ? true : false : false, [value, validate, required, touched])
  return <TextField
    {...props}
    required={required}
    variant="filled"
    margin="normal"
    fullWidth={true}
    value={value}
    onBlur={handleBlur}
    error={hasError}
    helperText={hasError ? errorMessage : undefined}
  />
}

const initialState = { name: '', from: '', subject: '', message: '' }
const url = 'https://www.serverlessmailer.com/send'

function ContactForm({ title, onSubmit }: { title?: string, onSubmit: (state: typeof initialState) => Promise<void> }) {
  const [state, setState] = useState(initialState)
  const [sending, setSending] = useState(false)
  const valid = useMemo(() => state.from !== '' && state.subject !== '' && state.message !== '', [state])
  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    setSending(true)
    e.preventDefault()
    await onSubmit(state)
  }, [onSubmit, state])
  return <form onSubmit={handleSubmit}>
    {title ? <Typography variant="h5">{title}</Typography> : null}
    <Box display="flex" flexDirection="column" marginBottom={2}>
      <Box display="flex">
        <EnhancedTextField label="Your name" style={{ marginRight: 16 }} value={state.name} onChange={e => setState(s => ({ ...s, name: e.target.value }))} />
        <EnhancedTextField
          label="Your e-mail address"
          required={true}
          errorMessage="Please enter a valid e-mail"
          value={state.from}
          onChange={e => setState(s => ({ ...s, from: e.target.value }))}
          validate={v => !v || v === '' || !emailRegex.test(String(v).toLowerCase())}
        />
      </Box>
      <EnhancedTextField label="Subject" required={true} errorMessage="This field is required" value={state.subject} onChange={e => setState(s => ({ ...s, subject: e.target.value }))} />
      <EnhancedTextField label="Message" required={true} errorMessage="This field is required" multiline={true} rows={5} value={state.message} onChange={e => setState(s => ({ ...s, message: e.target.value }))} />
      <Box display="flex" marginTop={1}>
        <span style={{ flex: 1 }} />
        <Button variant="contained" color="secondary" disabled={!valid || sending} type="submit">Send</Button>
      </Box>
    </Box>
  </form>
}

function Main({ props }: { props?: ContactFormBlockProps }) {
  const { pathname } = useLocation()
  const { page } = useParams<{ page?: string }>()
  const history = useHistory()
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = useCallback(async (state: typeof initialState) => {
    if (props?.apiKey) {
      try {
        await axios.post(url, { apiKey: props.apiKey, from: state.from, text: state.message, subject: `Bericht van ${state.name} via website, onderwerp: ${state.subject}` })
        setSubmitted(true)
        history.push(`/${page}/success`)
      } catch (e) {
        console.log('error...', e)
        // TODO: show error...
      }
    }
  }, [props?.apiKey, history, page])
  return pathname.indexOf('/success') < 0 ? <ContactForm title={props?.subHeading} onSubmit={handleSubmit} /> : submitted ? <Box display="flex" flexDirection="column" alignItems="center">
    <Typography gutterBottom={true}>{props?.feedbackMessage || 'Your message was sent succesfully!'}</Typography>
    <Button variant="contained" color="secondary" onClick={() => history.push(`/${page}`)}>Go back</Button>
  </Box> : <Redirect to={`/${page}`} />
}

function Edit({ props, onChange }: { props?: ContactFormBlockProps, onChange: (props?: ContactFormBlockProps) => void }) {
  return <Box height="100%" overflow="auto">
    <Box padding={2}>
      <TextField label="Titel" variant="filled" value={props?.subHeading} onChange={e => onChange({ ...props, subHeading: e.target.value })} />
      <TextField label="Serverless Mailer API key" variant="filled" value={props?.apiKey} onChange={e => onChange({ ...props, apiKey: e.target.value })} />
      <TextField label="Feedback message" variant="filled" value={props?.feedbackMessage} onChange={e => onChange({ ...props, feedbackMessage: e.target.value })} />
    </Box>
  </Box>
}

const definition: BlockDefinition = {
  icon: 'email',
  name: 'contactForm',
  title: 'Contact formulier',
  content: '',
  Main,
  Edit
}

export default definition
