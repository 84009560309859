import drawings from './blocks/drawings'
import richText from './blocks/richText'
import exhibitions from './blocks/exhibitions'
import contactForm from './blocks/contactForm'
import facebookLikeButton from './blocks/facebookLikeButton'
import simple_page from './simple_page'
import { Page } from '../Admin'

export type Content = {
  name: string,
  properties: string[],
  title: string,
  icon: string
}

export type PageTemplate = {
  name: string,
  title: string,
  Main: React.FC<{ page: Page }>,
  Edit: React.FC<{ page: Page, onChange: (page: Page) => void }>,
  type: 'page'
}

export const blocks = {
  drawings: { ...drawings },
  richText: { ...richText },
  exhibitions: { ...exhibitions },
  contactForm: { ...contactForm },
  facebookLikeButton: { ...facebookLikeButton }
}

export const pages = {
  simple_page: { ...simple_page }
}
