import { Box } from '@material-ui/core'
import React from 'react'
import { BlockDefinition, BlockProps } from '.'
import RichTextEditor from '../../components/RichTextEditor'

function Main({ props }: { props?: { content?: string } }) {
  return <Box dangerouslySetInnerHTML={{ __html: props?.content || '' }} />
}

function Edit({ props, onChange }: { props?: BlockProps, onChange: (props?: BlockProps) => void }) {
  return <RichTextEditor value={props?.content || ''} onChange={(e, content) => onChange({ ...props, content })} />
}

const definition: BlockDefinition = {
  icon: 'subject',
  name: 'richText',
  title: 'Rich Text',
  content: '',
  Main,
  Edit
}

export default definition
