import { Box, TextField } from '@material-ui/core'
import React from 'react'
import { BlockDefinition, BlockProps } from '.'

type FacebookLikeButtonBlockProps = BlockProps<{
  iframeSrc?: string
}>

function Main({ props }: { props?: FacebookLikeButtonBlockProps }) {
  return <iframe src={props?.iframeSrc} title="Facebook like button" scrolling="no" frameBorder={0} style={{ border: 'none', overflow: 'hidden', height: 80, width: '100%' }} />
}

function Edit({ props, onChange }: { props?: FacebookLikeButtonBlockProps, onChange: (props?: FacebookLikeButtonBlockProps) => void }) {
  return <Box height="100%" overflow="auto">
    <Box padding={2}>
      <TextField label="Iframe Source" variant="filled" value={props?.iframeSrc} onChange={e => onChange({ ...props, iframeSrc: e.target.value })} />
    </Box>
  </Box>
}

const definition: BlockDefinition = {
  icon: 'thumbUp',
  name: 'facebookLikeButton',
  title: 'Facebook like knop',
  content: '',
  Main,
  Edit
}

export default definition
