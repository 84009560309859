import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'typeface-roboto'
import 'material-design-icons/iconfont/material-icons.css'
import './index.css'
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core'
import { blue, pink } from '@material-ui/core/colors'
import firebaseApp from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/nl'
import Admin from './Admin'
import { FirebaseProvider } from './hooks/firebase'

moment.locale('nl')

const { REACT_APP_SITE_NAME, REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_PROJECT_ID, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_MESSAGING_SENDER_ID, REACT_APP_FIREBASE_APP_ID } = process.env

export const siteName = REACT_APP_SITE_NAME || ''

export const firebase = firebaseApp.initializeApp({
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID
})
export const storage = firebase.storage()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[700]
    },
    secondary: pink
  }
})

ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider app={firebase}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route path="/edit" component={Admin} />
              <Route component={App} />
            </Switch>
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </FirebaseProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
